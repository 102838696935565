@import 'styles/variables.scss';

section.layout {
  min-height: 100vh;
  max-height: 100vh;
  background-color: $grey-01;

  .header {
    &:global(.ant-layout-header) {
      height: auto;
      padding: 0;
      line-height: initial;
    }
    background-color: transparent;
    border-bottom: 1px solid $grey-03;
    display: flex;
    justify-content: flex-start;
  }
  .content {
    height: 100%;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
  }
}
