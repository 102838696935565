@import 'styles/variables.scss';

.editor {
  counter-reset: line;
  min-height: 100%;
  overflow: scroll;
  textarea:focus-visible {
    outline: none; // изменить для границы кодедитора
  }

  textarea {
    padding-left: 60px !important;
  }
  pre {
    padding-left: 60px !important;

    span.editorLineNumber {
      display: inline-block;
      position: absolute !important;
      left: 0px;
      color: #cccccc;
      text-align: right;
      width: 40px;
      font-weight: 100;
    }
  }
}
