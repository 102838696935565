@import 'styles/variables.scss';

.main {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  overflow: auto;

  .side_code_editor {
    flex: 20 0;
    background-color: $primary-white;
    overflow: auto;
  }
  .side_instruction {
    flex: 6 0;
    padding: 32px 27px 32px 40px;
    border-left: 1px solid $grey-03;
    position: relative;
    overflow-y: auto;
    .task_title {
      color: $primary-black;
      font: 700 24px/36px $font-family-caption;
    }
    .task_text {
      color: $grey-05;
      font: $body-regular-m;
    }
  }
}
