@import 'src/styles/variables';
@import 'src/styles/mixins';

.layout {
  @include custom_scrollbar;

  height: 100vh !important;

  .sider {
    background: $primary-white;
    position: relative;
    z-index: 999;

    .logo_container {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 24px 0;

      & > :first-child {
        transition: width 0.2s;
      }

      svg {
        transition: all 0.2s;
      }
    }

    .logo_placeholder {
      width: 0;
      color: $primary-blue-1;
      transition: all 0.2s;
    }

    // logo collapse animation
    &:global(.ant-layout-sider-collapsed) .logo_container {
      & > :first-child {
        svg {
          color: transparent;
          width: 0;
        }
      }

      .logo_placeholder {
        width: 24px;
      }
    }

    // collapse trigger
    :global(.ant-layout-sider-trigger) {
      background: transparent;
      color: $grey-04;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 0 20px;
      height: 56px;

      & > :global(.anticon) {
        width: 24px;
        height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  .main {
    background: transparent;

    .header {
      display: flex;
      align-items: center;
      gap: 18px;
      background: $primary-white;
      border: 1px solid $grey-01;

      :global(.ant-breadcrumb) {
        li a {
          color: $grey-03;
        }

        li:last-child a {
          color: $grey-04;
        }
      }

      & > :first-child {
        flex: 1;
      }

      .settings {
        width: 32px;
        height: 32px;
      }

      .notifications {
        width: 32px;
        height: 32px;
      }

      .divider {
        height: 50%;
        margin: 0;
      }
    }

    .content {
      background: transparent;
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
