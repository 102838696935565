@import 'src/styles/variables.scss';

.container {
  flex: 1 1;
  min-height: 0;
  border-radius: 10px;
  background-color: $primary-white;
  padding: 22px 0;

  .tabs {
    width: 100%;
    height: 100%;
    overflow: hidden;
    gap: 30px;

    :global(.ant-tabs-nav) {
      margin: 0;

      :global(.ant-tabs-nav-wrap) {
        padding-left: 20px;
      }
    }

    :global(.ant-tabs-content-holder) {
      flex: 1 1;
      min-height: 0;
      padding: 0 36px 0 30px;
      position: static;

      :global(.ant-tabs-content) {
        height: 100%;
      }
    }
  }
}
