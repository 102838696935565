@import 'styles/variables.scss'; 

.tooltip {
  &:global(.ant-tooltip) {
    max-width: 800px;
  }

  &:global(.ant-tooltip-placement-topRight .ant-tooltip-arrow) {
    right: 70px;
  }

  :global(.ant-tooltip-inner) {
    font: $body-regular-s;
    color: $primary-black;
    padding: 16px 12px;
    border-radius: 8px;
    white-space: nowrap;
  }
}
