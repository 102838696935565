@import 'styles/variables.scss';

.wrapper {
  .top_half {
    :global {
      .ant-typography + h4.ant-typography {
        margin-top: 0;
      }
      h4.ant-typography {
        margin-bottom: 0;
      }
      .ant-divider-horizontal {
        margin: 20px 0;
      }
    }

    margin-bottom: 20px;
    padding: 24px 24px 0;
    .avatar {
      margin-right: 15px;
    }

    .job {
      font: $caption-font;
    }
    .button_popover_col {
      margin-left: auto;
    }
  }

  .bottom_half {
    padding: 0px 24px 24px;
    .tags {
      margin-bottom: 16px;
      :global {
        .ant-tag {
          font: $caption-font;
          color: $primary-blue-3;
          border-radius: 4px;
          border: none;
          padding: 4px 10px;
          background: rgba(76, 115, 255, 0.1);
        }
      }
    }
  }
}
