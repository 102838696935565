@import '~antd/dist/antd.css';
@import 'variables';

h1,
h2,
h3,
h4 {
  &.ant-typography {
    color: $primary-black;
  }
}

h1.ant-typography {
  font: $h1-bold;
}

h2.ant-typography {
  font: $h2-semibold;
}

h3.ant-typography {
  font: $h3-regular;
  margin: 0;
}

h4.ant-typography {
  font: $h4-medium;
  margin: 0;
}

h5.ant-typography {
  font: $h5-medium;
}

//Ant rows
.ant-row-flex,
.ant-row {
  margin: 0;
}

// Ant-tabs

.ant-tabs {
  gap: 30px;

  .ant-tabs-nav-wrap {
    padding: 0 12px;
  }

  .ant-tabs-tab {
    font: $body-regular-m;
    padding: 8px 10px;
    color: $primary-black;
    position: relative;

    &:hover {
      color: $primary-blue-1;

      &::after {
        content: '';
        border-bottom: 2px solid $primary-blue-1;
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 0;
      }
    }

    &.ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        color: $primary-black;
      }
    }
  }

  .ant-tabs-ink-bar {
    background-color: $primary-blue-1;
  }
}

// Popover

.ant-popover {
  .ant-popover-inner {
    border-radius: 8px;
  }

  .ant-popover-inner-content {
    padding: 0;
  }
}

// input
.ant-form-item-label
label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.ant-form-item-label
label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  display: none;
}

.ant-input {
  border: 1px solid $grey-03;
  border-radius: 5px;
  color: $primary-black;

  &:focus {
    border: 1px solid $primary-blue-1;
    border-radius: 5px;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid $primary-blue-1;
    border-radius: 5px;
    box-shadow: none;
  }
}

.ant-select-selection-placeholder {
  color: $grey-04;
}

.ant-input::placeholder {
  color: $grey-04;
}

.ant-form-item-has-error input {
  border-color: $accent-red-1 !important;
}

.ant-form-item-explain-error {
  color: $accent-red-1 !important;
}

.ant-input-focused {
  border: 1px solid $primary-blue-1;
  border-radius: 5px;
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border: 1px solid $grey-03;
  border-radius: 5px;
  box-shadow: none !important;

  &:focus {
    border: 1px solid $primary-blue-1;
    border-radius: 5px;
    box-shadow: none;
  }

  &:hover {
    border: 1px solid $primary-blue-1;
    border-radius: 5px;
    box-shadow: none;
  }
}

.ant-input-affix-wrapper-focused {
  border-color: $primary-blue-1;
  box-shadow: unset;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: $primary-blue-1;
}
/* //ant modal
.ant-modal-mask {
  background-color: #bcbfcd !important;
  opacity: 0.6;
} */

.ant-modal-content {
  border-radius: 6px;
}

//message
.ant-message {
  top: 20px;
  // bottom: 20px;
  // left: 100px;
  // z-index: 20000;
  .ant-message-notice {
    // text-align: start;
    .ant-message-notice-content {
      display: inline-block;
      padding: 12px 18px;
      background: $primary-white;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      border-radius: 12px;
      pointer-events: all;
      font: $body-regular-s;

      .ant-message-custom-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 8px;
      }
    }
  }
}

// Switch
.ant-switch {
  width: 44px;
  height: 24px;
  background: $grey-03;

  &:hover {
    box-shadow: 0 0 0 0.5px $primary-blue-1;
  }

  &:focus {
    box-shadow: unset;
  }
}

.ant-switch-handle {
  top: 4px;
  left: 4px;
  width: 16px;
  height: 16px;
}

.ant-switch-checked {
  background: $primary-blue-1;
}

// Datepicker
.ant-picker-input {
  input {
    color: $primary-black;
  }
}

.ant-picker:hover,
.ant-picker-focused {
  border-color: $primary-blue-1;
}

.ant-picker-focused {
  box-shadow: unset;
}

.ant-picker-active-bar {
  background: $primary-blue-1 !important;
}

// Rangepicker calendar

.ant-picker-dropdown-range {
  font: $body-regular-s;
  font-size: 14px;
  line-height: 22px;

  // Cells
  .ant-picker-cell {
    color: $grey-03;

    &.ant-picker-cell-in-view {
      color: $primary-black;

      &.ant-picker-cell-today{
        .ant-picker-cell-inner::before{
          border-color: $primary-blue-1;
        }
      }

      &.ant-picker-cell-range-start,
      &.ant-picker-cell-range-end {

        &::before,
        .ant-picker-cell-inner {
          background: $primary-blue-1;
        }

        .ant-picker-cell-inner {
          border-radius: 5px;
        }
      }

      &.ant-picker-cell-in-range {
        color: $primary-white;
        border: 1px solid $primary-white;

        &::before {
          background: $primary-blue-3;
        }

        &.ant-picker-cell-range-hover-start,
        &.ant-picker-cell-range-hover-end {
          .ant-picker-cell-inner::after {
            background: $primary-blue-4;
          }
        }

        &.ant-picker-cell-range-hover::before {
          background: $primary-blue-4;
        }

        &.ant-picker-cell-range-hover-start {
          .ant-picker-cell-inner::after {
            border-radius: 5px 0 0 5px;
          }
        }

        &.ant-picker-cell-range-hover-end {
          .ant-picker-cell-inner::after {
            border-radius: 0 5px 5px 0;
          }
        }
      }
    }
  }
  // Header
  .ant-picker-header-view{
    font: $body-regular-s;
    button:hover{
      color: $primary-blue-1;
    }
  }
}

// Pagination
.ant-pagination.ant-pagination-mini {
  .ant-pagination-item {
    font: $body-regular-s;
    font-size: 14px;
    line-height: 22px;
    border-radius: 4px;
    border: 1px solid $primary-blue-1;
    background: transparent;
    color: $primary-black;

    &-active, &:hover {
      a {
        color: $primary-blue-1;
      }
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    .ant-pagination-item-link-icon {
      color: $primary-blue-1;
    }
  }

  .ant-pagination-prev,
  .ant-pagination-next {
    color: $grey-03;

    &:hover svg {
      color: $primary-blue-1;
    }
  }

  .ant-select-selector {
    border: 1px solid $grey-05;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
  .ant-select-selector {
    border-color: $primary-blue-1;
  }
}

// Checkbox
.ant-checkbox {
  border-radius: 4px;

  &:hover,
  &:focus {
    border-color: $primary-blue-1;
  }
}

.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $primary-blue-1;
  }
}

.ant-checkbox-indeterminate {
  .ant-checkbox-inner::after {
    background-color: $primary-blue-1;
  }
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: $primary-blue-1;
}
