@import 'src/styles/variables';

.menu {
  max-width: 100%;

  .menu_icon {
    width: 24px;
    height: 24px;
    flex-shrink: 0;
  }

  &:global(.ant-menu) {
    background: $primary-white;
    border: unset;
  }

  :global(.ant-menu-item) {
    font: $body-regular-m;
    color: $grey-04;
    margin: 0 !important;
    height: 56px !important;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 13px 20px !important;
    gap: 16px;

    &:active {
      background: $primary-blue-6;
    }
    a {
      color: inherit;
      &:hover {
        color: inherit;
      }
    }
    .menu_icon {
      color: $grey-04;
    }
  }

  :global(.ant-menu-item-active) {
    color: $primary-blue-1 !important;

    .menu_icon {
      color: $primary-blue-1;
    }
  }

  :global(.ant-menu-item-selected) {
    background: $primary-blue-6 !important;
    color: $primary-blue-1;

    .menu_icon {
      color: $primary-blue-1;
    }
  }
}
