@import 'styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 9px;
  height: 100%;

  .ellipse {
    position: absolute;
    width: 9px;
    height: 9px;
    margin-left: -50%;
    background-color: $primary-blue-1;
    border-radius: 9px;
    top: -9px;
  }
  .line {
    height: 51px;
    position: absolute;
    top: 8px;
    left: 3.4px;
    border-left: 3px solid $primary-blue-1;
  }
}
