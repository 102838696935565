@import 'styles/variables.scss';

.contacts_item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;

  font: $caption-font;
  :global {
    .anticon {
      font-size: 32px;
      margin-right: 8px;
    }
    .ant-typography {
      color: $grey-05;
    }
  }
}

.contacts_item + .contacts_item {
  margin-top: 8px;
}
