@import 'styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 0;
  max-height: 100%;

  .header {
    flex: 0 0;
    display: flex;
    justify-content: space-between;
    margin-top: 36px;
    h3 {
      font: $h3-semibold;
    }
  }

  .collapse {
    flex: 1;
    overflow-y: auto;
    min-height: 0;
    margin-top: 32px;
    padding-right: 1em;
    border: none;
    display: flex;
    flex-direction: column;
    gap: 12px;
    .panel {
      background-color: $grey-01;
      border-radius: 6px;
      border: none !important;
      .panel_header {
        display: flex;
        justify-content: center;
        input {
          width: 18px;
          height: 18px;
          margin-right: 16px;
        }
        font: $body-bold-s;
      }
      .description {
        padding: 1px 30px 12px 38px;
        font: $caption-font;
      }
    }
  }
}
