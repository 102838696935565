@import 'styles/variables.scss';

.container {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font: $body-regular-s;
  background-color: $primary-white;
  padding: 8px 0px;
  border-radius: 8px;

  li {
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    gap: 5px;
    padding: 8px 12px;
    color: $primary-black;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      background-color: $primary-blue-6;
      color: $primary-blue-1;
    }
    & a {
      color: inherit;
      &:hover,
      &:active,
      &:focus {
        color: inherit;
      }
    }
  }
}
