@import 'styles/variables.scss';

.root {
  display: block;
  padding-left: 12px;
  font: $caption-font;
  position: relative;
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;
  justify-content: space-between;
  max-height: 64px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  &:hover {
    cursor: pointer;
  }
  &.focused {
    display: -webkit-box;
    -webkit-line-clamp: unset;
    max-height: none;
    background-color: $primary-blue-5;
    &:hover {
      background-color: $primary-blue-5;
    }
  }
  &.chosen:hover {
    cursor: default;
  }
  &:hover {
    background-color: $primary-blue-6;
  }
}

.text {
  display: block;
  max-width: 660px;
}

.check_svg {
  position: absolute;
  right: 12px;
  top: 0;
  bottom: 0;
  margin: auto 0;
}
