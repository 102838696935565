@mixin custom_scrollbar {
  ::-webkit-scrollbar {
    width: 6px; /* ширина для вертикального скролла */
    height: 6px; /* высота для горизонтального скролла */
    background-color: $grey-02;
    border-radius: 24px;
  }

  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    background-color: $grey-03;
    border-radius: 24px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: $grey-04;
  }

  /* Стрелки */

  ::-webkit-scrollbar-button:vertical:start:decrement,
  ::-webkit-scrollbar-button:vertical:end:increment,
  ::-webkit-scrollbar-button:horizontal:start:decrement,
  ::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}

@mixin media-breakpoint-down($size) {
  @if $size == xxl {
    @media (max-width: 1400px) {
      @content;
    }
  } @else if $size == xl {
    @media (max-width: 1024px) {
      @content;
    }
  } @else if $size == lg {
    @media (max-width: 992px) {
      @content;
    }
  } @else if $size == md {
    @media (max-width: 768px) {
      @content;
    }
  } @else if $size == sm {
    @media (max-width: 576px) {
      @content;
    }
  } @else if $size == xsplus {
    @media (max-width: 400px) {
      @content;
    }
  } @else if $size == xs {
    @media (max-width: 360px) {
      @content;
    }
  }
}
