@import 'styles/variables.scss';

.controls {
  display: flex;
  width: 90px;
  justify-content: space-between;
  button {
    display: flex;
    align-items: center;

    height: 24px;
    background-color: $primary-white;
    border: none;
    &:hover {
      svg {
        path {
          fill: $grey-04;
        }
      }
    }
  }
  .play {
    height: 24px;
    svg {
      path {
        fill: $grey-03;
        stroke: $grey-03;
      }
    }
    &:hover {
      svg {
        path {
          fill: $grey-04;
          stroke: $grey-04;
        }
      }
    }
  }
  .forward {
    svg {
      transform: rotate(180deg);
    }
  }
}
