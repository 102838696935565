@import 'styles/variables.scss';

.info {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;

    .item {
      display: flex;
      min-width: 50%;
      width: 310px;
      height: 34px;

      span {
        display: flex;
        align-items: center;
      }

      span.title {
        width: 163px;
        font: $body-medium-s;
      }

      span.details {
        font: $body-regular-s;
      }

      .select {
        width: 170px;
      }
    }
  }
