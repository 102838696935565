@import 'styles/variables.scss';

.header_testing_wrapper {
  background-color: inherit;
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 33px 40px;
  @media (max-height: 800px) {
    padding: 16px 20px;
  }
  .logo_container {
    min-height: 40px;
    display: flex;
    align-items: center;
  }
  .timer {
    font: 500 31px/32px $font-family-base;
    color: $grey-05;
    &.overlimit {
      color: $grey-03;
    }
  }
}
