@import 'styles/variables.scss';

@mixin styled_button($background-color, $color, $border-color) {
  background-color: $background-color;
  color: $color;
  border-color: $border-color;
}
@mixin only_icon_disabled {
  @include styled_button($grey-03 !important, $primary-white !important, $grey-03 !important);
}

.button {
  :global {
    .ant-btn {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 8px 10px 8px 10px;
      gap: 6px;
      height: 40px;
      border-radius: 6px;
      font: $body-regular-s;
    }
    .ant-btn.only_icon_r {
      width: 32px;
      height: 32px;
      padding: 0;
      box-shadow: none;
      color: $primary-white !important;
      .anticon.anticon-more {
        display: contents;
      }
    }
    .ant-btn.only_icon_l {
      width: 24px;
      height: 24px;
      padding: 0;
      box-shadow: none;

      border: none;
      .anticon.anticon-more {
        display: contents;
      }
    }
    .ant-btn-primary {
      @include styled_button($primary-blue-1, $primary-white, $primary-blue-1);
      &:hover,
      &:focus {
        @include styled_button($primary-blue-2, $primary-white, $primary-blue-2);
      }
    }
    .ant-btn-primary.ant-btn.only_icon_l {
      @include styled_button(transparent !important, $primary-blue-1 !important, transparent);
      &:hover {
        color: $primary-blue-2 !important;
      }
    }
    .ant-btn-primary[disabled],
    .ant-btn-primary[disabled]:hover,
    .ant-btn-primary[disabled]:focus,
    .ant-btn-primary[disabled]:active {
      @include styled_button($grey-03, $primary-white, $grey-03);
    }

    .ant-btn-primary[disabled].only_icon_r,
    .ant-btn-primary[disabled]:hover.only_icon_r,
    .ant-btn-primary[disabled]:focus.only_icon_r,
    .ant-btn-primary[disabled]:active.only_icon_r {
      @include only_icon_disabled;
    }
    .ant-btn-primary[disabled].only_icon_l {
      @include styled_button($grey-03 !important, $primary-white !important, $grey-03 !important);
      &:hover,
      &:focus {
        @include styled_button($grey-03 !important, $primary-white !important, $grey-03 !important);
      }
    }

    .ant-btn-default {
      @include styled_button($grey-02, $primary-blue-1, $grey-02 !important);
      &:hover,
      &:focus {
        @include styled_button($grey-03, $primary-blue-2, $grey-03 !important);
      }
    }
    .ant-btn-default.only_icon_l {
      @include styled_button(transparent !important, $grey-03, transparent !important);
      &:hover,
      &:focus {
        @include styled_button(transparent !important, $grey-04, transparent !important);
      }
    }
    .ant-btn-default[disabled],
    .ant-btn-default[disabled]:hover,
    .ant-btn-default[disabled]:focus,
    .ant-btn-default[disabled]:active {
      @include styled_button($grey-03 !important, $primary-white !important, $grey-03 !important);
    }
    .ant-btn-default.only_icon_r {
      @include styled_button($grey-02, $primary-blue-1, transparent !important);
      &:hover,
      &:focus {
        @include styled_button($grey-03, $primary-blue-2, transparent !important);
      }
    }
    .ant-btn-default[disabled].only_icon_r,
    .ant-btn-default[disabled]:hover.only_icon_r,
    .ant-btn-default[disabled]:focus.only_icon_r,
    .ant-btn-default[disabled]:active.only_icon_r {
      @include only_icon_disabled;
    }
    .ant-btn-default[disabled].only_icon_l,
    .ant-btn-default[disabled]:hover.only_icon_l,
    .ant-btn-default[disabled]:focus.only_icon_l,
    .ant-btn-default[disabled]:active.only_icon_l {
      @include only_icon_disabled;
    }

    .ant-btn-dangerous {
      @include styled_button($accent-red-1, $primary-white !important, $accent-red-1 !important);
      &:hover,
      &:focus {
        @include styled_button($accent-red-2, $primary-white !important, $accent-red-2 !important);
      }
    }
    .ant-btn-dangerous.only_icon_r {
      @include styled_button(
        $accent-red-1 !important,
        $primary-white !important,
        $accent-red-1 !important
      );
      &:hover,
      &:focus {
        @include styled_button(
          $accent-red-2 !important,
          $primary-white !important,
          $accent-red-2 !important
        );
      }
    }
    .ant-btn-dangerous.only_icon_l {
      @include styled_button(
        transparent !important,
        $accent-red-1 !important,
        transparent !important
      );
      &:hover,
      &:focus {
        @include styled_button(
          transparent !important,
          $accent-red-2 !important,
          transparent !important
        );
      }
    }
    .ant-btn-dangerous[disabled].only_icon_r,
    .ant-btn-dangerous[disabled]:hover.only_icon_r,
    .ant-btn-dangerous[disabled]:focus.only_icon_r,
    .ant-btn-dangerous[disabled]:active.only_icon_r {
      @include only_icon_disabled;
    }
    .ant-btn-link {
      @include styled_button(transparent !important, $primary-blue-1, transparent !important);
      text-decoration-line: underline !important;
      text-decoration-skip-ink: none;
      &:hover,
      &:focus {
        @include styled_button(transparent !important, $primary-blue-2, transparent !important);
      }
    }
    .ant-btn-link[disabled],
    .ant-btn-link[disabled]:hover,
    .ant-btn-link[disabled]:focus,
    .ant-btn-link[disabled]:active,
    .ant-btn-link[disabled].ant-btn-dangerous,
    .ant-btn-link[disabled].ant-btn-dangerous:hover,
    .ant-btn-link[disabled].ant-btn-dangerous:focus,
    .ant-btn-link[disabled].ant-btn-dangerous:active {
      @include styled_button(transparent !important, $grey-04 !important, transparent !important);
    }
    .ant-btn-link.ant-btn-dangerous {
      @include styled_button(
        transparent !important,
        $accent-red-1 !important,
        transparent !important
      );
      &:hover {
        @include styled_button(
          transparent !important,
          $accent-red-2 !important,
          transparent !important
        );
      }
    }
  }
}
.fixed_width {
  width: 230px;
}
