@import 'styles/variables.scss';

.wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  .insctruction_container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 85vw;
    border-radius: 12px;
    box-shadow: 3px 6px 24px rgba(204, 210, 230, 0.26);

    .header {
      flex: 0;
      color: $primary-black;
      padding: 56px 56px 48px 56px;
      @media (max-height: 800px) {
        padding: 24px 20px 16px 20px;
      }
      .title {
        &:global(.ant-typography) {
          font: 700 24px/40px $font-family-caption;
          color: $primary-black;
        }
      }
    }
    .main {
      :global(.ant-typography) {
        font: $body-regular-s;
        color: $grey-05;
      }
      flex: 1;
      border-top: 1px solid $grey-02;
      border-bottom: 1px solid $grey-02;
      padding: 56px;
      @media (max-height: 800px) {
        padding: 16px;
      }
    }
    .footer {
      flex: 0;
      padding: 56px;
      @media (max-height: 800px) {
        padding: 16px 20px 24px 20px;
      }
      display: flex;
      justify-content: flex-end;
    }
  }
}
