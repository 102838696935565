@import 'styles/variables.scss';

:global {
  div.ant-modal-content {
    width: 627px;
    border-radius: 6px;
    overflow: hidden;
  }
  .ant-modal-wrap.ant-modal-centered {
    overflow: hidden;
  }
}
.wrapper {
  padding: 51px 76px;
  background-color: $primary-white;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 18px;
  border-radius: 15px;
  // box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px rgba(0, 0, 0, 0.08),
  //   0px 9px 28px 8px rgba(0, 0, 0, 0.05);

  .main {
    min-height: 80px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
    gap: 8px;

    .title {
      font: $h3-semibold;
      color: $primary-black;
      margin-bottom: 0;
    }

    .content {
      font: $body-regular-m;
      color: $grey-04;
      text-align: center;
      white-space: normal;
    }
  }
}
.footer {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
}
