@import "styles/variables";

.wrapper {
  padding: 30px 32px 34px;
  overflow: auto;
  min-height: 100%;

  .container {
    width: fit-content;
    min-height: 100%;
    padding: 40px 62px;
    border-radius: 8px;
    background-color: $primary-white;
    background-image: url("~assets/images/svg/main_page_background_shape.svg");
    background-repeat: no-repeat;
    background-size: 180% 190%;
    background-position: 48% 30%;

    display: flex;
    gap: 56px;
    flex-wrap: nowrap;
    align-items: center;

    .content {
      flex: 0 1 60%;
      min-width: 300px;

      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .title {
        margin-bottom: 12px;

        .logo {
          color: $primary-blue-1;
        }
      }

      .subtitle {
        font: $h4-medium;

        margin-bottom: 26px;
        color: $grey-05;
      }

      .list {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        margin-bottom: 38px;

        font: $h4-medium;
        color: $grey-05;

        li:before {
          content: '\2022';
          margin: 12px;
        }
      }
    }

    .button {
      width: 224px;
      height: 48px;
    }

    .picture {
      flex: 0 1 40%;
      min-width: 300px;
      max-height: 600px;
    }

  }
}
