@import 'styles/variables.scss';

.progress_item {
  width: 26px;
  height: 8px;
  background-color: $grey-02;

  &:first-child {
    border-radius: 24px 0px 0px 24px;
  }
  &:last-child {
    border-radius: 0px 24px 24px 0px;
  }
  &.blue {
    background-color: $primary-blue-1;
  }
  &.red {
    background-color: $accent-red-1;
  }
  &.green {
    background-color: $accent-green-1;
  }
  &.orange {
    background-color: $accent-orange-1;
  }
}
