@import 'styles/variables.scss';

.content {
  background-color: $primary-white;
  border-radius: 6px;
  margin: 36px 48px;
  padding-bottom: 45px;
  padding-left: 36px;
  padding-right: 36px;
  padding-top: 44px;
}
