.root {
  display: flex;
  flex-direction: column;
  width: 786px;
  margin-top: 38px;
  &:first-child {
    margin-top: 24px;
  }
}

.question_and_score {
  display: flex;
  justify-content: space-between;
  margin-bottom: 18px;
}

.question {
  width: 607px;
  &.non_tech {
    width: 100%;
  }
}

.score {
  width: 155px;
}

.comments textarea {
  resize: none;
}

.comments,
.mark,
.question {
  margin-bottom: 0px;
}

.text_area {
  resize: none;
  overflow-y: auto !important;
}
