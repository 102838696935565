@import 'src/styles/variables.scss';

.wrapper_statistic_list {
  display: grid;
  grid-gap: 6px 24px;
  justify-content: stretch;
  overflow-x: auto;

  .item {
    &_caption {
      font: $body-medium-s;
      grid-row: 1;
    }

    &_content {
      font: $body-regular-s;
      grid-row: 2;
      align-self: center;
    }
  }
}
