@import 'src/styles/variables';

.form_wrapper {
  background-color: $primary-white;
  border-radius: 20px;
  margin: 36px 48px;
  padding-bottom: 45px;
  padding-top: 44px;

  .input_col {
    height: 80px;
  }

  .page_title {
    margin-left: 12px;

    font: $h3-semibold;
  }

  .section_title {
    margin-top: 35px;
    margin-bottom: 27px;
    padding-left: 12px;
    height: 37px;
    background-color: $primary-blue-6;
    font: $body-regular-m;
    color: $primary-black !important;
    line-height: 37px;
  }

  .divider {
    margin: 0;
    margin-top: 44px;
    margin-bottom: 32px;
  }

  label {
    font: $caption-font;
    color: $primary-black !important;
  }
}

.add_vacancy_button {
  width: 100%;

  display: block;
  text-align: left;
  padding: 7px;
  border: none;
  background-color: inherit !important;
  color: $primary-black;

  &:hover {
    background-color: $primary-blue-6 !important;
  }
}

.popconfirm_overlay {
  :global(.ant-popover-inner-content) {
    padding: 12px 16px !important;
  }
}
