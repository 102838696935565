@import 'styles/variables.scss';

.wrapper {
  padding: 36px 48px 26px 48px;
  height: 100%;
}

.container {
  background-color: white;
  border-radius: 8px;
  height: 100%;
  padding-top: 30px;
  padding-bottom: 40px;
  display: flex;
  flex-direction: column;
  :global {
    .ant-form-item-explain-connected {
      height: auto;
    }

    .ant-form-item-with-help .ant-form-item-explain {
      min-height: 0;
    }
    .ant-input[disabled] {
      background-color: white;
      cursor: auto;
      color: $grey-05;
      border-color: $grey-04;
      &:hover {
        border-color: $grey-04;
      }
    }
    .ant-form-item-with-help .ant-form-item-explain {
      height: 0px;
      min-height: 0px;
    }
    .ant-row-flex,
    .ant-row {
      margin: 0 !important;
    }
    .ant-form-item-label
      label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
      display: inline-block;
      margin-right: 4px;
      color: #ff4d4f;
      font-size: 14px;
      font-family: SimSun, sans-serif;
      line-height: 1;
      content: '*';
    }
    .ant-form-item-label
      label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
      display: none;
    }

    .ant-form-item-label {
      font: $caption-font;
    }

    .ant-form-item-explain-connected {
      display: none !important;
    }

    .ant-form-item-with-help .ant-form-item-explain {
      display: none !important;
    }

    .ant-input {
      border: 1px solid $grey-04;
      height: 42px;
      border-width: 1px;
      border-radius: 5px;
      box-sizing: border-box;
      font: $caption-font;
      padding-top: 11px;
      padding-bottom: 11px;
      &:focus {
        border: 1px solid $primary-blue-1;
        border-radius: 5px;
        box-shadow: none;
      }
      &:hover {
        border: 1px solid $primary-blue-1;
        border-radius: 5px;
        box-shadow: none;
      }
    }
    .ant-form-item-has-error input {
      border-color: $accent-red-1 !important;
    }
    .ant-form-item-explain-error {
      color: $accent-red-1 !important;
    }
    .ant-input-focused {
      border: 1px solid $primary-blue-1;
      border-radius: 5px;
      box-shadow: none;
    }
    .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
      .ant-select-selector {
      border: 1px solid $primary-blue-1;
    }
    .ant-select:not(.ant-select-customize-input) .ant-select-selector {
      height: 42px !important;
      border: 1px solid $grey-04;
      color: $grey-05;
      border-radius: 5px;
      box-shadow: none !important;
      &:focus {
        border: 1px solid $primary-blue-1;
        border-radius: 5px;
        box-shadow: none;
      }
      &:hover {
        border: 1px solid $primary-blue-1;
        border-radius: 5px;
        box-shadow: none;
      }
    }
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 72px;
  padding-bottom: 32px;
  padding-left: 30px;
}

.points_container {
  display: flex;
  align-items: center;
  .points_heading {
    font: $body-bold-m;
    margin-right: 24px;
    &.h3 {
      margin-bottom: 0px !important;
    }
  }
  .points {
    border-radius: 4px;
    background-color: $primary-blue-6;
    font: $caption-font;
    padding: 4px 10px;
    color: $primary-blue-1;
  }
}

.form {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  min-height: 0px;
}

.blocks_container {
  flex-grow: 1;
  overflow-y: auto;
  min-height: 0px;
  padding-right: 60px;
  & > div {
    padding-left: 30px;
  }
}

.line {
  height: 1px;
  background-color: $grey-01;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  &.lineTop {
    top: 0;
  }
}

.button_container {
  flex-grow: 0;
  padding-top: 24px;
  padding-left: 30px;
  position: relative;
}
