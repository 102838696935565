* {
  box-sizing: border-box;
  list-style: none;
  padding: 0;
  margin: 0;
}

/* scale fix */
html,
body {
  width: 100%;
  height: 100%;
  overflow: hidden;
}
