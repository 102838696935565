.wrapper {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  overflow-y: auto;
}
.modal_footer {
  display: flex;
  gap: 8px;
}
