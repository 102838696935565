@import 'src/styles/variables';

.form {
  display: flex;
  width: 100%;
  flex-direction: column;

  .input {

    &_email {
      margin-bottom: 15px !important;

      .clear_field_svg {
        display: flex;
        align-items: center;
        width: 16px;
      }
    }

    &_password {
      margin-bottom: 25px !important;
    }

    :global(.ant-input-suffix) {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .login_form_error {
    color: $accent-red-1;
    margin-top: 5px;
  }
}


