@import "src/styles/variables";
@import "src/styles/mixins";

// FILTERS
.candidate_list_filters {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: stretch;
  margin-bottom: 32px;
  gap: 11px;

  .search {
    border-radius: 5px;
    flex: 1;
    min-width: 150px;

    :global(.ant-input-clear-icon) {
      display: flex;
      align-items: center;

      svg {
        width: 16px;
        height: 16px;

        * {
          stroke: $grey-03;
        }

        &:hover * {
          stroke: $primary-blue-1;
        }
      }
    }

    :global(.ant-input-prefix) {
      color: $grey-03;
      margin-right: 8px;
    }

    input::placeholder {
      color: $grey-04;
    }
  }

  .date_filter {
    border-radius: 5px;

    input {
      font-size: 14px !important;

      &::placeholder {
        color: $grey-04;
      }
    }
  }


  .other_filters_label {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: 1px solid $primary-blue-1;
    color: $primary-blue-1;
    border-radius: 5px;
    padding: 10px 12px;
    transition: all 0.5s;

    &:hover {
      color: $primary-white;
      background: $primary-blue-1;
    }
  }

  .only_chosen_filter {
    display: flex;
    align-items: center;
    gap: 8px;

    &_label {
      font: $caption-font;
      color: $grey-05;
    }
  }

  .reset_btn {
    color: $grey-04;
    text-decoration-line: none !important;

    &_active, &:active {
      color: $primary-blue-1;
    }

    &:focus {
      color: $grey-04;
    }

    &_active, &:focus {
      &:hover {
        color: $primary-blue-2
      }
    }
  }
}

.date_filter_dropdown {
  :global {
    .ant-picker-panels {
      & > :not(:first-child) {
        display: none;
      }

      .ant-picker-header-next-btn,
      .ant-picker-header-super-next-btn {
        visibility: visible !important;

        &:hover{
          color: $primary-blue-1;
        }
      }
    }
  }
}

.other_filters_overlay {
  @include custom_scrollbar;

  background: $primary-white;
  width: 260px;
  overflow: hidden;
  border-radius: 6px;
  box-shadow: 1px 1px 8px -2px;

  :global(.ant-collapse-content) {
    background-color: $grey-01 !important;
    max-height: 200px;

    &:global(.ant-collapse-content-active) {
      overflow-y: auto;
    }
  }

  :global(.ant-checkbox-group) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
  }
}