@font-face {
  font-family: 'Poppins';
  src: local('Poppins Bold'), local('Poppins-Bold'),
    url('../assets/fonts/Poppinsbold.ttf') format('truetype'),
    url('../assets/fonts/Poppinsbold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins Poppins-BoldSemiBold'), local('Poppins-SemiBold'),
    url('../assets/fonts/Poppins-SemiBold.ttf') format('truetype'),
    url('../assets/fonts/Poppins-SemiBold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Bold'), local('Roboto-Bold'),
    url('../assets/fonts/Roboto-Bold.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto Medium'), local('Roboto-Medium'),
    url('../assets/fonts/Roboto-Medium.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), local('Roboto-Regular'),
    url('../assets/fonts/Roboto-Regular.ttf') format('truetype'),
    url('../assets/fonts/Roboto-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}
