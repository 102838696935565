@import "src/styles/variables";

.badge {
  :global(.ant-badge-dot) {
    width: 33.3%;
    height: 33.3%;
    margin-top: 25%;
    right: 33.3%;
  }
}

.icon {
  color: $grey-03;
  width: 100%;
  height: 100%;
}