@import 'src/styles/variables';
@import "src/styles/mixins";

.layout {
  display: flex;
  height: 100vh;
  width: 100%;
  background: $primary-white;
  overflow: auto;

  .side_wrapper {
    min-width: 0;
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;

    background: $primary-blue-6;
    padding: 80px;
    overflow: hidden;

    .picture {
      flex: 1;
      max-width: 100%;
      min-width: 400px;
      align-self: center;
    }

    @include media-breakpoint-down(lg) {
      flex-basis: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }

  .main_wrapper {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 80px 0;
    margin: 0 auto;


    .logo {
      display: none;
      margin-left: 36px;
      align-self: flex-start;
      min-height: 25px;

      @include media-breakpoint-down(lg) {
        display: unset;
      }
    }

    .form {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 400px;
      margin: 100px 36px;

      .title {
        margin-bottom: 50px;
      }
    }

    .copyright {
      display: flex;
      flex-direction: column;
      align-items: center;

      font: $body-regular-s;
      color: $grey-05;

      a {
        color: $primary-blue-1;
        text-decoration-line: underline;
      }
    }
  }

  // Buttons
  :global(.ant-btn) {
    border-radius: 5px;
    border: 0;

    span {
      font: $body-regular-s;
    }
  }

  :global(.ant-btn-primary) {
    color: $primary-white;
    background-color: $primary-blue-1;

    &:hover {
      background-color: $primary-blue-2;
    }

    &:active,
    &:focus {
      background-color: $primary-blue-1;
    }

    &:disabled,
    &.ant-btn-loading {
      background-color: $grey-04;
    }
  }

  // Inputs
  :global(.ant-form-item-explain-connected) {
    height: auto;
  }

  :global(.ant-form-item-with-help .ant-form-item-explain) {
    min-height: 0;
  }

  :global(.ant-input-prefix) {
    margin-right: 8px;
  }

  :global(.ant-input-suffix:hover) {
    cursor: pointer;
  }

  :global(.ant-input-lg::placeholder) {
    font: $caption-font;
    color: $grey-04 !important;
  }

  :global(.ant-input-lg) {
    font: $caption-font;
  }

  :global(.ant-input-affix-wrapper) {
    border-radius: 5px;
    border-color: $grey-03;
  }

  :global(.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover) {
    border-color: $primary-blue-1;
  }

  :global(.ant-input-affix-wrapper:focus),
  :global(.ant-input-affix-wrapper-focused) {
    box-shadow: none !important;
    border-color: $primary-blue-1;
  }
}
