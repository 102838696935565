@import 'styles/variables.scss';

.wrapper {
  overflow-x: scroll;
  overflow-y: hidden;
  position: relative;
}
.timeline {
  display: flex;
  margin-top: 9px;
  width: 100%;
  flex-wrap: nowrap;
  white-space: nowrap;
  height: 50px;
  background-color: $grey-01;
}
