@import 'styles/variables.scss';

.description {
  display: flex;
  margin-top: 24px;
  height: 42px;
  background-color: $grey-01;
  padding: 11px 12px 11px 12px;
  font: $body-regular-s;

  span {
    margin-top: auto;
  }
  span:nth-of-type(1) {
    color: $primary-blue-1;
    font: $body-bold-m;
    padding-right: 10px;
  }
}
