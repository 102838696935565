@import "src/styles/variables";

.white {
  color: $primary-white;
}

.black {
  color: $primary-black;
}

.blue {
  color: $primary-blue-1;
}