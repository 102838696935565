@import 'styles/variables.scss';

.skeleton_avatar {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.container {
  list-style-type: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
  font: $body-regular-s;
  background-color: $primary-white;
  padding: 8px 0px;
  border-radius: 8px;
}

.avatar {
  cursor: pointer;

  .image {
    width: 44px;
    height: 44px;
    border-radius: 100px;
  }
}

.popover {
  padding-top: 0px !important;
}
