@import 'styles/variables.scss';

.button {
  border: none;
  outline: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  gap: 5px;
  padding: 8px 12px;
  color: $accent-red-1;
  transition: color 0.3s;
  &:hover {
    background-color: $primary-blue-6;
  }
}
