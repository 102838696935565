.questions_list {
  max-height: 206px;
  background: #ffffff;
  box-shadow: 3px 6px 24px rgba(204, 210, 230, 0.26);
  border-radius: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
  overflow-y: auto;
}

.input {
  :global(.ant-input-prefix) {
    margin-right: 10px;
  }
}
