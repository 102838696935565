@import 'styles/variables.scss';

.wrapper {
  height: 100%;
  max-height: 100%;
  overflow-x: auto;
  align-items: stretch;
  justify-content: space-between;
  padding: 32px;
  gap: 16px;

  .statistic_results {
    flex: 1 1;
  }

  .personal_and_comments {
    flex: 0 0;
    min-width: 340px;
    max-width: 340px;

    .personal {
      flex: 0 0;
    }
    .comments {
      flex: 1 1;
    }
  }

  & :global {
    .ant-col {
      display: flex;
      flex-direction: column;
      max-height: 100%;
      gap: 16px;
    }
  }
}
