.edit_buttons_container {
  display: flex;
  gap: 16px;
}

.empty_block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
