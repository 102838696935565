@import "src/styles/variables";

.wrapper {
  height: 100%;
  width: 100%;
  padding: 32px;

  .container {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    background: $primary-white;
    border-radius: 20px;
    width: 100%;
    height: 100%;
    padding: 32px 32px 28px 32px;

    .title {
      font: $h3-semibold;
      margin-bottom: 32px;
      align-self: start;
    }

    // TABLE
    .table {
      flex: 1 1 auto;
      min-height: 0;

      :global(.ant-table-header) {
        border-radius: 5px;
        margin-bottom: 8px;

        :global(.ant-table-cell) {
          background: $primary-blue-6;
          color: $primary-black;
          font: $caption-font;
          padding: 19px 8px;
        }

        // fix permanent header scrollbar shadow
        :global(.ant-table-cell-scrollbar) {
          box-shadow: none;
        }
      }

      .table_row {
        min-height: 76px;

        .score {
          font: $caption-font;
          color: $grey-05;
        }

        .date {
          font: $caption-font;
          color: $grey-05;
        }

        .avatar {
          flex-shrink: 0;
        }

        .candidate_info {
          display: flex;
          flex-direction: row;
          flex-wrap: nowrap;
          align-items: center;
          gap: 11px;

          .column {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 400px;

            .name {
              font: $body-medium-s;
              color: $primary-black;
            }

            .vacancy {
              font: $caption-font;
              color: $grey-05;
            }
          }
        }
      }

      :global(.ant-table-placeholder) {
        .empty_result {
          :global(.ant-result-icon) {
            margin-bottom: 27px;
            svg {
              opacity: 0.55;
            }
          }

          :global(.ant-result-subtitle) {
            font: $body-bold-s;
            color: $grey-05;
          }
        }
      }

      // Таблица на всю доступную высоту
      :global {
        .ant-spin-nested-loading {
          height: 100%;

          .ant-spin-container {
            height: 100%;
            display: flex;
            flex-flow: column nowrap;

            .ant-table {
              flex: auto;
              overflow: hidden;

              .ant-table-container {
                height: 100%;
                display: flex;
                flex-flow: column nowrap;

                .ant-table-header {
                  flex: none;
                }

                .ant-table-body {
                  flex: auto;
                  overflow: auto !important;

                }
              }
            }

            .ant-table-pagination {
              flex: none;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
}
