@import 'styles/variables.scss';

.container {
  flex: 1 0;
  display: flex;
  flex-direction: column;
  min-height: 0;

  padding: 24px 18px 18px 24px;
  background-color: $primary-white;
  border-radius: 6px;

  :global {
    .ant-list-vertical {
      flex: 1 0;
      overflow-y: auto;
      margin-top: 10px;
      min-height: 0;
      .ant-list-item {
        padding: 14px 0px;
        .ant-list-item-meta {
          margin-bottom: 8px;

          .ant-list-item-meta-title {
            margin-bottom: 2px;
            font: $body-bold-s;
            color: $grey-05;
          }
          .ant-list-item-meta-description {
            font: $caption-font;
            color: $grey-05;
          }
        }
      }
    }
    .ant-input-affix-wrapper {
      border: 1px solid $grey-03;
      border-radius: 5px;
      font: $body-regular-s;
      margin-top: 16px;
    }
  }
  .title_4 {
    font: 500 20px/23px $font-family-base;
    color: $primary-black;
  }
  .list_item_content {
    .item_content_text {
      font: $caption-font;
      color: $primary-black;
      margin-bottom: 0px;
      padding-right: 12px;
      white-space: pre-wrap;
      word-break: break-all;
    }
    .item_content_date {
      font: $caption-meduim-s;
      color: $grey-04;
      text-transform: uppercase;
    }
  }
  .send_comment {
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-end;
    gap: 8px;
    margin-top: 20px;
    position: relative;

    :global {
      .ant-input-textarea {
        flex: 1;
        .ant-input {
          padding: 12px 56px 8px 12px;
          font: $caption-font;
          border-radius: 5px;
          &:focus {
            border-color: $primary-blue-1;
          }
        }
      }
    }
    .send_button {
      margin-bottom: 22px;
    }
  }
  .empty {
    flex: 1 0;
  }
}
