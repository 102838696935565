@import 'styles/variables.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;

  .header {
    display: flex;
    justify-content: space-between;
    padding: 36px 0px 24px 0px;

    h3 {
      font: $h3-semibold;
    }
  }

  .editor_wrapper {
    flex: 1 1;

    overflow-y: auto;
    padding-right: 30px;

    flex-direction: column;
    margin-bottom: 6px;
    border: 1px solid $grey-02;
    border-radius: 8px;
  }
}
