@import 'styles/variables.scss';

.wrapper_stage_progress {
  display: flex;
  flex-direction: row;
  gap: 8px;
  .stage_title {
    font: $caption-font;
  }
  .wrapper_progress_bar {
    height: 18px;
    border-radius: 24px;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: 2px;
  }
}
