@import 'styles/variables.scss';

.player_input {
  display: flex;
  align-items: center;
  .player_time_mark {
    text-align: center;
    line-height: 18px;
    height: 18px;
    color: $grey-04;
    margin: 8px;
  }
  .player_input_slider {
    width: 100%;
  }

  :global {
    .ant-slider-track {
      background-color: $primary-blue-3;
      height: 8px;
      border-radius: 20px;
    }
    .ant-slider:hover .ant-slider-track {
      background-color: $primary-blue-3;
    }
    .ant-slider-step {
      height: 8px;
      border-radius: 20px;
    }
    .ant-slider-rail {
      height: 8px;
      background-color: $grey-02;
      border-radius: 20px;
    }
    .ant-slider-handle {
      width: 16px;
      height: 16px;
      border: 3px solid $primary-blue-1 !important;
      box-shadow: none !important;
    }
  }
}
