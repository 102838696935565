@import 'styles/variables.scss';
.wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  
  
  .container {
    width: 630px;
    padding: 51px 76px;
    background-color: $primary-white;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 18px;
    border-radius: 15px;

    .main {
      min-height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0;
      gap: 8px;

      .title {
        font: $h3-semibold;
        color: $primary-black;
        margin-bottom: 0;
      }

      .content {
        font: $body-regular-m;
        color: $grey-04;
        text-align: center;
      }
    }
  }
}
