@import 'styles/variables.scss';
@import 'src/styles/mixins';

.root {
  padding-top: 32px;
  position: relative;
  margin-top: 40px;
  &:first-child {
    margin-top: 0px;
    .line {
      display: none;
    }
  }
  &:last-child {
    margin-bottom: 30px;
  }
}
.block_name {
  font: $body-regular-m;
}

.search_modal {
  @include custom_scrollbar;
  ::-webkit-scrollbar-thumb {
    background-color: $grey-04;
  }
  ::-webkit-scrollbar-thumb:hover {
    background-color: $grey-05;
  }
  ::-webkit-scrollbar-track {
    background-color: inherit;
    margin-right: 3px;
    border-radius: 100px;
  }
  ::-webkit-scrollbar {
    padding-right: 3px;
  }

  :global(.ant-modal-content) {
    width: 768px;
    border-radius: 0px;
    background-color: transparent;
  }
  :global(.ant-input) {
    border-radius: 0px;
    &:hover,
    &:focus {
      border-radius: 0px;
    }
  }
  :global(.ant-input-affix-wrapper-lg) {
    border-radius: 5px;
  }
}

.line {
  height: 1px;
  background-color: $grey-01;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
}

.buttons_container {
  margin-top: 32px;
  display: flex;
  gap: 16px;
}
