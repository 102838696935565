@import 'styles/variables.scss';

.interview_content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  .debug_add {
    align-self: end;
  }

  .tools {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    user-select: none;

    svg {
      width: 24px;
      height: 24px;
      color: $grey-04;
      cursor: pointer;
    }

    :global(.ant-radio-group) {
      display: flex;
      flex-direction: row;
      gap: 12px;

      :global(.ant-radio-wrapper) {
        font: $caption-font;
        color: $grey-05;
        background-color: $grey-01;
        border-radius: 4px;
        transition: all 0.3s;
        margin: 0;
        padding: 4px 10px;

        span {
          padding: 0;
        }

        :global(.ant-radio) {
          display: none;
        }
      }

      :global(.ant-radio-wrapper-checked) {
        background-color: $primary-blue-3;
        color: $primary-white;
      }
    }
  }

  .questions_list {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-self: stretch;
    overflow-y: auto;
    padding-right: 30px;

    .question {
      margin-bottom: 8px;
      background: $primary-white;
      border-radius: 6px;
      padding: 12px 0px 12px 21px;
      box-shadow: 3px 6px 24px rgba(204, 210, 230, 0.26);

      :global(.ant-collapse-header) {
        color: $primary-black;
        font: $body-bold-s;
        align-items: center;
        padding-left: 16px;
        border-left: 2px solid $primary-blue-1;

        :global(.ant-collapse-header-text) {
          margin-right: 22px;
        }
      }

      :global(.ant-collapse-content) {
        padding-left: 16px;
        border-left: 2px solid $primary-blue-1;

        :global(.ant-collapse-content-box) {
          padding-left: 0;
        }
      }
    }

    // Custom arrow
    :global(.ant-collapse-arrow) {
      color: $grey-03;
      transition: all 0.3s;
    }

    :global(.ant-collapse-item-active .ant-collapse-arrow) {
      transform: rotate(180deg) !important;
      transform-origin: 50% 30%;
    }
  }

  :global(.ant-divider-horizontal) {
    margin: 30px 0;
  }

  .summary {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .label {
      font: $body-bold-m;
    }
  }

  // Иногда не переопределяются без important
  .tag {
    width: fit-content;
    min-width: 80px;
    text-align: center;
    font: $caption-font !important;
    padding: 4px 10px !important;
    border: 0 !important;
    border-radius: 4px !important;
    &.tag-blue {
      background-color: rgba(76, 115, 255, 0.1);
      color: $primary-blue-3;
    }
    &.tag-red {
      background-color: rgba(254, 61, 1, 0.1);
      color: $accent-red-1;
    }
    &.tag-orange {
      background-color: rgba(255, 214, 102, 0.1);
      color: $accent-orange-2;
    }
    &.tag-green {
      background-color: rgba(0, 208, 58, 0.1);
      color: $accent-green-1;
    }
  }
}

.empty_result_wrapper {
  flex: 1;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .empty_result {
    overflow: auto;
    padding: 0;
    display: flex;
    flex-direction: column;

    :global(.ant-result-icon) {
      opacity: 0.5;
      // margin-bottom: 50px;
    }

    :global(.ant-result-subtitle) {
      font: $body-regular-s;
      color: $primary-black;
    }

    :global(.ant-result-extra) {
      display: flex;
      justify-content: center;
      // margin-top: 50px;
    }
  }
}
