//colors
$primary-blue-1: #0033EF;
$primary-blue-2: #0F2A88;
$primary-blue-3: #4C73FF;
$primary-blue-4: #99AFFF;
$primary-blue-5: #CCD7FF;
$primary-blue-6: #E0E7FF;
$primary-black: #0E0F16;
$primary-white: #FFFFFF;
$grey-05: #50597C;
$grey-04: #A2AAC3;
$grey-03: #C1C6D7;
$grey-02: #E6E8EF;
$grey-01: #F3F4F7;
$accent-red-1: #FE3D01;
$accent-red-2: #CC3100;
$accent-red-3: #AE2D04;
$accent-green-1: #00D03A;
$accent-green-2: #00B332;
$accent-green-3: #00992B;
$accent-orange-1: #FFD666;
$accent-orange-2: #F8A401;
$accent-orange-3: #EC8104;
$accent-orange-4: #D48806;
$accent-blue-1: #91D5FF;
$accent-blue-2: #0181F8;
$accent-blue-3: #005AE1;
$accent-blue-4: #002766;
$accent-purple-1: #D3ADF7;
$accent-purple-2: #9E02FF;
$accent-purple-3: #6F00B3;
$accent-purple-4: #531DAB;
$accent-yellow-1: #FFFB8F;
$accent-yellow-2: #FFE817;
$accent-yellow-3: #F9D908;
$accent-yellow-4: #D4B106;
$accent-cyan-1: #87E8DE;
$accent-cyan-2: #36CFC9;
$accent-cyan-3: #13C2C2;
$accent-cyan-4: #08979C;
$accent-magenta-1: #FFADD2;
$accent-magenta-2: #F759AB;
$accent-magenta-3: #C41D7F;
$accent-magenta-4: #9E1068;
$accent-lime-01: #EAFF8F;
$accent-lime-02: #D3F261;
$accent-lime-03: #BAE637;
$accent-lime-04: #A0D911;

//font
$font-family-caption: 'Poppins', sans-serif;
$font-family-base: 'Roboto', sans-serif;

$h1-bold: 700 38px/46px $font-family-caption;
$h2-semibold: 600 30px/40px $font-family-caption;
$h3-semibold: 600 24px/32px $font-family-base;
$h3-regular: 400 24px/32px $font-family-base;
$h4-medium: 500 20px/28px $font-family-caption;
$h5-medium: 500 16px/22px $font-family-base;
$body-regular-m: 400 18px/21px $font-family-base;
$body-medium-m: 500 18px/21px $font-family-base;
$body-bold-m: 700 18px/21px $font-family-base;
$body-regular-s: 400 16px/19px $font-family-base;
$body-medium-s: 500 16px/19px $font-family-base;
$body-bold-s: 700 16px/19px $font-family-base;
$caption-font: 400 14px/18px $font-family-base;
$caption-meduim-s: 500 12px/14px $font-family-base;